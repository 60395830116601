import { mapGetters } from 'vuex'

import General from '../general/index.vue'
import Access from '../access/index.vue'
import Courses from '../courses/index.vue'

export default {
  name: 'education-tariffs-dialog-view',
  data () {
    return {
      dialog: true,
      activeTab: 'general',
    }
  },
  components: {
    General,
    Access,
    Courses,
  },
  computed: {
    ...mapGetters({
      currentItem: 'educationTariffs/currentItem',
	  isLoading: 'educationTariffs/isLoading',
    }),
    tabs () {
      return this.currentItem ? [
        {
          name: 'General',
          slug: 'general',
          id: 1
        },
        // {
        //   name: 'Access',
        //   slug: 'access',
        //   id: 2,
        //   disabled: !this.currentItem
        // },
        {
          name: 'Courses',
          slug: 'courses',
          id: 3
        },
      ] : [
        {
          name: 'General',
          slug: 'general',
          id: 1
        }
      ]
    }
  },
  methods: {
    changeCloseDialog () {
      this.dialog = false
      this.$emit('close-dialog')
    },

	save(){
	  this.$refs['general'].$emit('event-save');
	}
  },
}
