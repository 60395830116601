import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'

import mixins from '@/mixins'

import ViewDialog from './dialog-view/index.vue'

import { educationTariffsTableHead } from '@/modules/education/helpers/tariffs'

export default {
    name        : 'education-tariffs',
    mixin       : [mixins],
    data () {
        return {
            page            : 1,
            showRemoveDialog: false,
            removingItem    : null,
            openDialogView  : false,
            editedItem      : null
        }
    },
    components  : {
        ViewDialog
    },
    computed    : {
        ...mapGetters({
            list            : 'educationTariffs/list',
            isListLoading   : 'educationTariffs/isListLoading',
            isLoading       : 'educationTariffs/isLoading',
            listLength      : 'educationTariffs/listLength',
            limit           : 'educationTariffs/limit',
            setting         : 'courses/setting',
            accessCountries : 'profile/accessCountries',
            filterKeywords  : 'educationTariffs/filterKeywords',
            filterCountries : 'educationTariffs/filterCountries',
            filterStatus    : 'educationTariffs/filterStatus'
        }),
        educationTariffsTableHead
    },
    created () {
        if (!this.list.length && this.$route.params && this.$route.params.course_id) {
            this.fetchList({
                course_id: this.$route.params.course_id
            })
        }else{
            this.changeFilterLocal(2, 'status');
        }
    },
    methods     : {
        ...mapActions({
            fetchList       : 'educationTariffs/GET_LIST',
            remove          : 'educationTariffs/DELETE',
            copy            : 'educationTariffs/COPY'
        }),
        ...mapMutations({
            setCurrentItem  : 'educationTariffs/SET_ITEM',
            changeSkip      : 'educationTariffs/CHANGE_SKIP',
            changeFilter    : 'educationTariffs/CHANGE_FILTER'
        }),
        copyCurrentItem(item) {
            this.copy(item).then(() => {
                this.fetchList()
            })
        },
        changeFilterLocal (value, type) {
            const obj = {
                type    : type,
                value   : value
            };
            
            this.changeFilter(obj)
            //this.changeSkip(0)
            this.sendRequest()
            
            this.page = 1;
        },
        sendRequest: debounce(function () {
            this.changeSkip(0);
            
            this.fetchList()
        }, 1000),
        changePagination (index) {
            this.changeSkip(this.limit * (index - 1));
            
            this.$vuetify.goTo(0);
            
            this.fetchList()
        },
        closeDialogView () {
            setTimeout(() => {
                this.openDialogView = false
            }, 500)
        },
        changeDialogView (item = null) {
            this.setCurrentItem(item);
            
            this.openDialogView = true
        },
        removeLocal () {
            this.remove(this.removingItem).then(() => {
                this.showRemoveDialog = false;
                
                this.changeSkip(0);
                
                this.$toasted.success(this.$t('success_deleted'))
            })
        },
        changeDialogDeleteItem (item) {
            this.showRemoveDialog   = true;
            this.removingItem       = item;
        }
    }
}
