import { mapActions, mapGetters } from 'vuex'
import { snakeCase } from 'lodash'
import moment from 'moment'

import mixins from '@/mixins'
import validation from '@/mixins/validation'

import MainEditor from '@/components/editor/index.vue'
import ImageFile from '@/components/image-file/index.vue'

import { educationTariffsTypes } from '@/modules/education/helpers/tariffs'

export default {
    name    : 'education-streams-general',
    mixin   : [mixins, validation],
    data () {
        return {
            valid               : false,
            search              : null,
            searchInput         : null,
            openDatepicker      : false,
            validationErrors    : {},
            payload             : {
                countryId                   : '',
                statusId                    : '',
                typeId                      : '',
                sort                        : '',
                name                        : '',
                adminDescription            : '',
                description                 : '',
                price                       : '',
                currencyId                  : '',
                days                        : '',
                dateEnd                     : '',
                crmId                       : '',
                demoId                      : '',
                preCampaign                 : '',
                successCampaign             : '',
                payType                     : '',
                
                isHidden                    : false,
                isRenewable                 : false,
                
                activationBanner            : false,
                accessToAllContent          : false,
                showUpsellForYearOnUserCard : false,
            },
            payTypes            : [
                {
                    key     : 'per_month',
                    label   : 'per month'
                },
                {
                    key     : 'annual',
                    label   : 'annual'
                }
            ],
            isHidden            : [
                {
                    key     : false,
                    label   : 'no'
                },
                {
                    key     : true,
                    label   : 'yes'
                }
            ]
        }
    },
    components: {
        MainEditor,
        ImageFile
    },
    watch: {
        currentItem () {
            this.setData()
        }
    },
    computed: {
        ...mapGetters({
            educationTariffs    : 'educationTariffs/list',
            isLoading           : 'educationTariffs/isLoading',
            setting             : 'courses/setting',
            currentItem         : 'educationTariffs/currentItem',
            profile             : 'profile/profile',
            isCountryLoading    : 'setting/isCountryLoading',
            selectedCountry     : 'setting/selectedCountry',
            accessCountries     : 'profile/accessCountries',
            educationCurrencies : 'setting/currencies',
            updateImageLoading  : 'modules/updateImageLoading'
        }),
        currencies () {
            if (!this.selectedCountry) return []
            return this.selectedCountry.currencies.data.map(e => e.currency)
        },
        educationTariffsTypes,
        computedDateFormatted () {
            if (!this.payload.dateEnd) return ''
            
            return moment(this.payload.dateEnd).format('DD.MM.YYYY')
        },
    },
    created () {
        if (this.currentItem) {
            this.setData()
        }
        
        this.fetchCurrencies({
            filters: {
                slug : ['USD', 'EUR', 'UAH', 'INR']
            }
        });
        
        this.fetchTariffs({});
        
        this.$on('event-save', () =>{
            this.submit();
        });
    },
    methods: {
        ...mapActions({
            fetchTariffs    : 'educationTariffs/GET_LIST',
            update          : 'educationTariffs/UPDATE',
            create          : 'educationTariffs/CREATE',
            fetchCurrencies : 'setting/GET_CURRENCIES_LIST',
            updateImage     : 'educationTariffs/UPDATE_IMAGE'
        }),
        updateImageLocal (params) {
            params.append('tariff_id', this.currentItem.id)
            params.append('type_id', 2)
            
            this.updateImage(params).then(() => {
                this.$toasted.success(this.$t('success_updated'))
            })
        },
        formattedDate (date) {
            if (!date) return ''
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
        setData () {
            Object.assign(this.payload, this.currentItem)
        },
        createRequestPayload () {
            const data      = Object.assign(this.payload);
            const formData  = {};
            
            for (const i in data) {
                formData[snakeCase(i)] = data[i]
            }
            
            formData.activationBanner               = this.payload.activationBanner ? 1 : 0;
            formData.accessToAllContent             = this.payload.accessToAllContent ? 1 : 0;
            formData.showUpsellForYearOnUserCard    = this.payload.showUpsellForYearOnUserCard ? 1 : 0;
            
            formData.isHidden                       = this.payload.isHidden ? 1 : 0;
            formData.isRenewable                    = this.payload.isRenewable ? 1 : 0;
            
            if (this.currentItem) {
                formData._method = 'patch'
            }
            
            return formData
        },
        dateBlur (event) {
            if(!event.target.value){
                this.payload.dateEnd = '';
            }
        },
        submit () {
            this.$refs.form.validate();
            
            if (this.valid) {
                if (this.currentItem) {
                    this.update(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('success_updated'));
                    })
                } else {
                    this.create(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('success_created'));
                    })
                }
            }
        }
    }
}
