import {mapActions, mapGetters, mapMutations} from 'vuex'
import validationMixin from '@/mixins/validation'
import {debounce} from 'lodash'
import {tariffCourseListTableHead} from '../../helpers'

export default {
    name    : 'user-admins-courses',
    mixins  : [validationMixin],
    data() {
        return {
            valid                   : false,
            validUpdateProgressForm : false,
            page                    : 1,
            limit                   : 15,
            skip                    : 0,
            showDialogCourse        : false,
            showRemoveDialog        : false,
            showDialogUpdateProgress: false,
            deletedItemData         : null,
            editedItem              : null,
            keywords                : '',
            payload                 : {
                courseId    : '',
                packageId   : ''
            },
            search                  : null,
            searchInput             : null,
            selectedPackages        : [],
            selectedCoursePackage   : null
        }
    },
    computed: {
        ...mapGetters({
            currentItem             : 'educationTariffs/currentItem',
            
            userCourses             : 'userCourses/userCourses',
            userCoursesLength       : 'userCourses/userCoursesLength',
            isUserCoursesLoading    : 'userCourses/isUserCoursesLoading',
            isLoading               : 'userCourses/isLoading',
            courses                 : 'userCourses/courses',
            isCoursesLoading        : 'userCourses/isCoursesLoading',
            packagesCourse          : 'userCourses/packagesCourse',
            isPackagesCourseLoading : 'userCourses/isPackagesCourseLoading',
            
            isTariffCoursesLoading  : 'educationTariffsCourse/isListLoading',
            tariffCoursesItems      : 'educationTariffsCourse/list',
            tariffCoursesLength     : 'educationTariffsCourse/listLength',
            tariffCourseList        : 'educationTariffsCourse/coursesList',
            //limit                   : 'educationTariffsCourse/limit',
            //skip                    : 'educationTariffsCourse/skip'
        }),
        tariffCourseListTableHead,
        currentTariffCourse() {
            if (this.editedItem !== null) {
                return this.getCurrentTariffCourse(this.editedItem.courseId)
            };
            
            return null;
        }
    },
    watch   : {
        packagesCourse() {
            this.selectedPackages = this.packagesCourse.map(p => {
                p.isSelected = false;
                
                if (this.editedItem !== null) {
                    const tariffCourse = this.tariffCoursesItems ? this.tariffCoursesItems.find((c) => c.courseId === this.editedItem.courseId) : [];
                    
                    if (tariffCourse) {
                        //const tariffPackage = tariffCourse.packages ? tariffCourse.packages.find((_p) => p.id === _p.packageId) : [];
                        
                        const tariffPackage = tariffCourse.package ? tariffCourse.package : null;
                        
                        if (tariffPackage && tariffPackage.packageId == p.id) {
                            p.isSelected = true;
                            
                            this.selectedCoursePackage = p.id;
                        }
                    }
                }
                
                return p;
            });
        },
        selectedCoursePackage() {
            this.selectedPackages = this.selectedPackages.map(p => {
                p.isSelected = false;
                
                if (p.id === this.selectedCoursePackage) {
                    p.isSelected = true;
                }
                
                return p;
            })
        }
    },
    created() {
        this.loadCourses().then(() => {
            if (this.skip !== 0) {
                this.page = Math.round(this.skip / this.limit) + 1
            }
        })
    },
    methods : {
        ...mapActions({
            fetchCourses          : 'userCourses/GET_COURSES',
            fetchPackages         : 'userCourses/PACKAGES_GET_LIST',
            fetchUserCourses      : 'userCourses/GET_USER_COURSES',
            
            fetchCourseModules    : 'courses/GET_COURSE_MODULES',
            fetchCourseMaterials  : 'courses/GET_COURSE_MATERIALS',
            
            remove                : 'educationTariffsCourse/DELETE',
            update                : 'educationTariffsCourse/UPDATE',
            create                : 'educationTariffsCourse/CREATE',
            fetchTariffCourses    : 'educationTariffsCourse/GET_LIST',
            fetchTariffCoursesList: 'educationTariffsCourse/GET_COURSES'
        }),
        ...mapMutations({
            //changeSkip        : 'userCourses/USER_COURSES_CHANGE_SKIP',
            setUserCourses    : 'userCourses/SET_USER_COURSES',
            setCourses        : 'userCourses/SET_COURSES',
            setPackages       : 'userCourses/PACKAGES_SET_LIST',
            
            setTariffCourses  : 'educationTariffsCourse/SET_COURSES_LIST',
            changeSkip        : 'educationTariffsCourse/CHANGE_SKIP'
        }),
        getCurrentTariffCourse(courseId) {
            return this.tariffCoursesItems.find((c) => c.courseId === courseId)
        },
        async loadCourses() {
            await this.fetchTariffCourses({
                tariffId: this.currentItem.id
            });
            
            if (this.tariffCoursesItems.length) {
                await this.fetchTariffCoursesList({
                    ids: this.tariffCoursesItems.map(c => c.courseId)
                });
            } else {
                this.setTariffCourses([])
            }
        },
        changeInputCourse: debounce(function (val) {
            if (!val) return;
            
            if (this.keywords !== val) {
                this.keywords = val;
                
                this.fetchCourses({
                    keywords: val,
                    id      : this.payload.courseId
                })
            }
        }, 500),
        autoCompleteChanged(e) {
            this.setPackages([]);
            this.payload.courseId = e.id;
            this.search = e;
            
            this.fetchPackages({
                courseId: e.id
            });
        },
        changeDialogCourse(item = null, status = false) {
            this.setPackages([]);
            
            this.showDialogCourse   = status;
            this.editedItem         = item;
            
            if (status) {
                if (item) {
                    this.payload.courseId = item.courseId;
                    
                    this.fetchPackages({
                        courseId: item.courseId
                    }).then();
                } else {
                    this.setCourses([]);
                    
                    this.fetchCourses({
                        keywords: ''
                    });
                }
            } else {
                this.payload.courseId   = '';
                this.payload.packageId  = '';
                
                this.search         = null;
                this.searchInput    = null;
            }
        },
        removeItem() {
            this.remove({
                id: this.getCurrentTariffCourse(this.deletedItemData.courseId).id
            })
            .then(() => {
                this.showRemoveDialog = false;
                
                this.$toasted.success(this.$t('success_deleted'));
                
                this.loadCourses();
            })
        },
        changeDialogDelete(item = null) {
            this.showRemoveDialog   = !!item;
            this.deletedItemData    = item;
        },
        changePagination(index) {
            this.skip = this.limit * (index - 1);
            
            //this.changeSkip(skip);
            
            if (this.skip !== 0) {
                this.page = Math.round(this.skip / this.limit) + 1;
            }else{
                this.page = 1;
            }
            
            this.$vuetify.goTo(0);
            
            /*
            this.fetchUserCourses({
                userId: this.$route.params.id
            });
            */
        },
        submit() {
            if (this.editedItem) {
                const data = {
                    course_id   : this.editedItem.courseId,
                    tariff_id   : this.currentItem.id,
                    packages    : this.selectedPackages.filter(p => p.isSelected).map(p => p.id),
                    id          : this.currentTariffCourse.id
                };
                
                this.update(data).then(() => {
                    this.$toasted.success(this.$t('success_updated'));
                    this.changeDialogCourse();
                    this.loadCourses();
                });
            } else {
                const data = {
                    course_id   : this.search.id,
                    tariff_id   : this.currentItem?.id,
                    packages    : this.selectedPackages.filter(p => p.isSelected).map(p => p.id)
                };
                
                this.create(data).then(() => {
                    this.$toasted.success(this.$t('success_created'));
                    this.changeDialogCourse();
                    this.loadCourses();
                });
            }
        }
    },
    destroyed() {
        this.setUserCourses([]);
        this.setTariffCourses([]);
    }
}
