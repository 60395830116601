import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'

import { educationTariffsAccessTabs } from '@/modules/education/helpers/tariffs'
export default {
  name: 'education-tariffs-access',
  data () {
    return {
      dialog: true,
      activeTab: '',
      limit: 21,
      skip: 0,
      page: 0,
      checkboxes: []
    }
  },
  created () {
    this.changeTab(this.tabs[0])
  },
  computed: {
    ...mapGetters({
      currentItem: 'educationTariffs/currentItem',
      listLength: 'educationTariffs/educationListLength',
      list: 'educationTariffs/educationList',
      listLoading: 'educationTariffs/educationListLoading'
    }),
    educationTariffsAccessTabs
  },
  watch: {
    list () {
      this.setTariffs()
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'educationTariffs/GET_CURRENT_EDUCATION',
      checkItem: 'educationTariffs/CHECK_EDUCATION_LIST_ITEM'
    }),
    ...mapMutations({
      setList: 'educationTariffs/SET_CURRENT_EDUCATION'
    }),
    setTariffs () {
      this.list.forEach(e => {
        const item = e.tariffs.data.find(t => t.id === this.currentItem?.id)
        if (item) {
          this.checkboxes.push(item.id)
        }
      })
    },
    changeTab (item) {
      this.page = 0
      this.skip = 0
      this.checkboxes = []
      this.setList(null)
      this.activeTab = item.slug
      this.fetchList({
        slug: this.activeTab,
        limit: this.limit,
        skip: this.skip,
        id: this.currentItem?.id,
        countryId: this.currentItem.countryId
      })
    },
    sendRequest: debounce(function (params) {
      this.fetchList(params)
    }, 200),
    loadMore () {
      this.page++
      this.skip = this.limit * this.page
      this.fetchList({
        slug: this.activeTab,
        limit: this.limit,
        skip: this.skip,
        id: this.currentItem.id,
        countryId: this.currentItem.countryId
      })
    },
    changeCheckbox (item) {
      this.checkItem({
        slug: this.tabs.find(e => e.slug === this.activeTab).slug,
        tariffId: this.currentItem.id,
        id: item.id,
        isChecked: !!item.tariffs.data.find(e => e.id === item.id)
      }).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    }
  }
}
