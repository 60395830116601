export function tariffCourseListTableHead () {
    return [
        {
            text    : 'ID',
            value   : 'id'
        },
        {
            text    : 'Course ID',
            value   : 'courseId'
        },
        {
            text    : 'Name',
            value   : 'name',
            sort    : (a, b) => {
                const nameA = a.data.name.toLowerCase();
                const nameB = b.data.name.toLowerCase();
                
                if (nameA > nameB) {
                    return -1
                }
                
                if (nameA < nameB) {
                    return 1
                }
                
                return 0
            }
        },
        {
            text    : 'Package',
            value   : 'package'
        },
        {
            text    : 'Actions',
            value   : 'actions',
            sortable: false
        }
    ]
}
